@import "mixins";

$greenBackground: #284c5c;
$mustard: #deb42c;
$grey: #bec9ce;
$greyBackground: #e4e8e9;
$lightGreen: rgba(0, 102, 106, 0.12);
$lightYellow: rgba(222, 180, 44, 0.12);
$swooshGreen: #007a74;
$fontColor: rgba(40, 76, 92, 1);
$logoSVGGreen: #007a74;
$logoSVGYellow: #deb42c;
$padding: 10vw;
$mediumPadding: 15vw;
$imgSize: 75%;
$medium: 1025px;
$large: 1600px;

html {
  font-family: "Open Sans Regular", "Arial", "Tahoma", "Verdana", sans-serif;
}

$min_width: 320px;
$max_width: 1600px;
$min_font: 12px;
$max_font: 24px;

// see https://css-tricks.com/snippets/css/fluid-typography/
html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

h1 {
  font-size: $mod_1 * $mod_1 * $mod_1 * $mod_1 * 1rem;
  @include fluid-type(
    $min_width,
    $max_width,
    $mod_1 * $mod_1 * $mod_1 * $min_font,
    $mod_2 * $mod_2 * $mod_2 * $min_font
  );
  letter-spacing: 0.0625em;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}

h2 {
  font-size: $mod_1 * $mod_1 * $mod_1 * 1rem;
  @include fluid-type(
    $min_width,
    $max_width,
    $mod_1 * $mod_1 * $mod_1 * $min_font,
    $mod_2 * $mod_2 * $mod_2 * $min_font
  );
  letter-spacing: 0.0625em;
}

h3 {
  font-size: $mod_1 * $mod_1 * 1rem;
  @include fluid-type(
    $min_width,
    $max_width,
    $mod_1 * $mod_1 * $min_font,
    $mod_2 * $mod_2 * $min_font
  );
  letter-spacing: 0.0625em;
}

p {
  /* 16 * 0.0625 = 1px */
  letter-spacing: 0.0625em;
  line-height: 1.5em;
  font-size: 1.25em;
}

input,
textarea,
select,
button {
  font: inherit;
  letter-spacing: 0.0625em;

  &:active {
    border-color: $mustard;
  }
}

body {
  margin: 0;
  padding: 0;
}

.container {
  width: 100vw;
  overflow: hidden;
}

header {
  background-color: $greenBackground;
  overflow: hidden;

  & > div {
    height: 100vh;
    min-height: 320px;
    display: grid;
    grid-template: "logo login" 3fr "headline headline" 1fr "kicker kicker" 1fr "signup signup" 4fr;
    align-items: center;
    background-image: url("hologram.svg");
    background-size: calc(100vmax * 0.7);
    background-repeat: no-repeat;
    background-position: right -40vw bottom -10vh;
    color: white;
    z-index: -2;
    overflow: hidden;
    padding: 0 $padding;
  }
}

#logo {
  grid-area: logo;
  width: 10em;
}

button {
  font-size: 20px;
  border: 1px solid $mustard;
  background: transparent;
  padding: 0.4em 0.8em;
  border-radius: 3px;
  color: $mustard;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

button:hover {
  color: white;
  background: $mustard;
  cursor: pointer;
}

#login {
  grid-area: login;
  justify-self: end;
}

#headline {
  grid-area: headline;
}

#kicker {
  grid-area: kicker;
  color: rgba(190, 201, 206, 1);
}

#signUp {
  grid-area: signup;
  display: flex;

  button {
    background: transparent;
    color: $mustard;
  }

  button:hover {
    background: $mustard;
    color: white;
  }

  input {
    margin-right: 1em;
    background: transparent;
    min-width: 130px;
    max-width: 300px;
    flex-basis: auto; /* default value */
    flex-grow: 1;
    padding: 0.4em 0.8em;
    border: 1px solid $mustard;
    border-radius: 3px;
    color: white;

    &::placeholder {
      color: $grey;
    }
  }
}

main > div {
  padding: 15vmin $padding 0 $padding;
  background-color: $greyBackground;
}

.content {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 10vmin;
  align-items: center;
  color: $fontColor;
}

.illustration {
  width: $imgSize;
  max-width: 70vh;
  padding-top: 30px;
}

.logoImg {
  background: $lightGreen;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border-radius: 50%;

  svg {
    width: 50%;

    g {
      stroke: $logoSVGGreen;
    }
  }
}

.sketchUnderline {
  position: relative;

  svg {
    position: absolute;
    left: 0;
    top: 0.65em;
    width: 90%;
  }
}

.content:nth-child(even) {
  .logoImg {
    background-color: $lightYellow;

    svg {
      g {
        stroke: $logoSVGYellow;
      }
    }
  }

  .sketchUnderline {
    svg {
      g {
        fill: $logoSVGGreen;
      }
    }
  }
}

#mockup {
  position: relative;
  z-index: 0;
  padding-bottom: 30px;

  svg:first-child {
    position: absolute;
    left: calc(-#{$imgSize} / 2 - #{$padding} + 30px);
    z-index: -1;
    top: -5%;
  }
}

@media only screen and (min-width: $medium) {
  header {
    position: relative;
    height: 90vh;
    min-height: 600px;
    max-height: 900px;
    transform: skew(12deg, 4deg);
    transform-origin: top right;
    left: -30px;
    border-radius: 0 0 0 60px;
    width: calc(100% + 30px);

    & > div {
      height: 100%;
      transform: skew(-12deg, -4deg);
      transform-origin: top right;
      grid-template: "logo login" 3fr "headline headline" 1fr "kicker kicker" 1fr "signup signup" 2fr ". ." 2fr;
      padding: 0 $mediumPadding;
      background-position: right -35vw top -85px;
      background-size: 900px;
    }
  }

  @media only screen and (min-height: 730px) and (max-width: 1080px) {
    header > div {
      background-position: right -45vw top -65px;
    }
  }
  @media only screen and (min-width: 1200px) {
    header > div {
      background-position: left 60vw top 0px;
    }
  }

  main {
    position: relative;
    transform: skew(-4deg, -6deg);
    transform-origin: bottom right;
    border-radius: 60px 0 0 0;
    left: -30px;
    width: calc(100vw + 30px);
    height: calc(100% - 150px);
    overflow: hidden;
    margin-top: -150px;
    z-index: -1;

    & > div {
      display: grid;
      transform: skew(4deg, 6deg);
      transform-origin: bottom right;
      padding: 200px calc(#{$mediumPadding} / 2) 0 $mediumPadding;
    }

    .content {
      flex-direction: row-reverse;
    }

    .contentBody {
      padding-right: 5%;
    }

    .illustration {
      svg {
        width: 30vw;
      }
    }

    .content:nth-child(even) {
      flex-direction: row;

      .contentBody {
        padding-right: 0;
        padding-left: 5%;
      }

      #mockup {
        svg:first-child {
          left: calc(-#{$mediumPadding} - 30px);
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  main {
    & > div {
      padding-top: 250px;
    }
  }
}

.contact {
  background: $greyBackground;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;

  .button {
    font-size: 20px;
    border: 1px solid #284c5c;
    color: #284c5c;
  }

  .button:hover {
    color: white;
    background: #284c5c;
  }

  a {
    text-decoration: none;
    color: $fontColor;
    font-size: 20px;
  }

  h3 {
    color: $fontColor;
  }
}

.subtleLink {
  text-decoration: none;
  color: inherit;
}

.subtleLink:hover {
  cursor: pointer;
}
